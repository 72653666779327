import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout/Layout";
import SEO from "../components/seo/Seo";
import Page from "../components/page/Page";
import CGU from "../components/cgu/CGU";
import i18nContext from "../components/i18n/i18n.context";

function CGUPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["gatsby", "react"]}
          location={location}
          metaDescription="Sur cette page, vous trouverez les termes et conditions de Jawg."
          title="CGU"
        />
        <Page>
          <CGU />{" "}
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

CGUPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default CGUPage;
